import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import { Link } from 'gatsby'

/* Plantilla para mostrar un artículo
  site.siteMetadata.title: Título del sitio
  site.siteMetadata.url: URL del sitio
  post.title: Título del artículo
  post.slug: Slug del artículo (URL)

  Helmet sirve para añadir metadatos a la página

  Los metadatos están especificados en gatsby-config.js

  */

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulBlogPost')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const url = get(this, 'props.data.site.siteMetadata.url')

    return (
      <Layout location={this.props.location}>
        <section className="bg-white mt-20">
          <h2 className="text-3xl font-bold text-gray-900 mx-10">
            {post.title}
          </h2>
          <div className="max-w-1xl p-1 mx-10 flex flex-wrap overflow-hidden">
            <Helmet title={`${post.title} | ${siteTitle}`}>
              <meta name="description" content={post.title} />
              <link rel="canonical" href={`${url}/entradas/${post.slug}`} />
            </Helmet>
            <div className="wrapper overflow-hidden w-full lg:w-4/5 ">
              <div class="mt-2 flex items-center text-sm text-gray-500">
                <svg
                  class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    clip-rule="evenodd"
                  />
                </svg>
                {post.publishDate}
              </div>
              <div
                className="mt-2 text-gray-700"
                dangerouslySetInnerHTML={{
                  __html: post.body.childMarkdownRemark.html,
                }}
              />
            </div>
            <div className="flex justify-center p-2  w-full lg:w-1/5">
              <Img
                className="w-80 h-80 rounded-full justify-center"
                alt={post.title}
                fluid={post.heroImage.fluid}
              />
            </div>
            <div className="mt-5 hover:text-blue-600">
              <Link to="entradas">
                <svg className="svg-icon" viewBox="0 0 20 20">
                  <path d="M11.739,13.962c-0.087,0.086-0.199,0.131-0.312,0.131c-0.112,0-0.226-0.045-0.312-0.131l-3.738-3.736c-0.173-0.173-0.173-0.454,0-0.626l3.559-3.562c0.173-0.175,0.454-0.173,0.626,0c0.173,0.172,0.173,0.451,0,0.624l-3.248,3.25l3.425,3.426C11.911,13.511,11.911,13.789,11.739,13.962 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.148,3.374,7.521,7.521,7.521C14.147,17.521,17.521,14.148,17.521,10"></path>
                </svg>
                <h3>Volver</h3>
              </Link>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default BlogPostTemplate

/**
 * Consulta GraphQL para obtener los datos de la página
 * contentfulBlogPost: consulta para obtener los datos de una sola entrada. Esta consulta es propia del plugin de Contentful
 * Hacemos un query con el slug de la entrada para obtener los datos de esa entrada en concreto.
 */

export const pageQuery = graphql`
         query BlogPostBySlug($slug: String!) {
           site {
             siteMetadata {
               title
               url
             }
           }
           contentfulBlogPost(slug: { eq: $slug }) {
             title
             slug
             publishDate(
               formatString: "D [de] MMMM [del] YYYY"
               locale: "es-es"
             )
             heroImage {
               fluid(maxWidth: 1180, background: "rgb:000000") {
                 ...GatsbyContentfulFluid_tracedSVG
               }
             }

             body {
               childMarkdownRemark {
                 html
               }
             }
           }
         }
       `
